@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap');

body {
  margin: 0;
  display: flex;
  font-family: 'Comic Neue', cursive;
}

html, body {
  height: 100%;
  width: 100%;
  background-color: silver;
}

#root {
  width: 100%;
  height: 100%;
}